'use client';

import React, { createContext, useState, useContext } from 'react';
import axios from 'axios';

// Create a Context
const BookingsBoxContext = createContext();

// Create a Provider Component
function BookingsBoxProvider({children}) {
  const [periodUsers, setPeriodUsers] = useState([]);
  const [periodBookedDates, setPeriodBookedDates] = useState([]);
  const [periodBookedSpecialClientDates, setPeriodBookedSpecialClientDates] = useState([]);
  const [periodVIPClients, setPeriodVIPClients] = useState([]);
  const [periodBookedHotelDates, setPeriodBookedHotelDates] = useState([]);
  const [periodBookedCasinoDates, setPeriodBookedCasinoDates] = useState([]);
  const [periodBookedEventsDates, setPeriodBookedEventsDates] = useState([]);
  const [periodBookedGRATDates, setPeriodBookedGRATDates] = useState([]);
  const [periodBookedCasinoOwnerDates, setPeriodBookedCasinoOwnerDates] = useState([]);
  const [periodDays, setPeriodDays] = useState([]);

  const getPeriodDays = async (currentMonth, currentYear) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*', 
          "Cache-Control": "no-cache",
        },
      };
      const response = await axios.get(`/api/period-view-booked-users?firstMonth=${currentMonth}&year=${currentYear}`, config);
      console.log('REACHED', response)
      
      setPeriodUsers(response.data.bookedUsersMCodes);
      setPeriodBookedDates(response.data.allMCodes);
      setPeriodBookedSpecialClientDates(response.data.allSpecialClientIds);
      
      const uniqueBookedUsersMCodes = response.data.bookedUsersMCodes.filter((item, index, array) => {
        const mCodes = array.map(user => user.mCode);
        return mCodes.indexOf(item.mCode) === index;
      });
      setPeriodVIPClients(uniqueBookedUsersMCodes);

      const allHotels = response.data.allSpecialClientIds.filter((id) => id.clientType === 'hotel');
      setPeriodBookedHotelDates(allHotels);
      const allCasinos = response.data.allSpecialClientIds.filter((id) => id.clientType === 'casino');
      setPeriodBookedCasinoDates(allCasinos);
      const allEvents = response.data.allSpecialClientIds.filter((id) => id.clientType === 'events');
      setPeriodBookedEventsDates(allEvents);
      const allGRAT = response.data.allSpecialClientIds.filter((id) => id.clientType === 'grat');
      setPeriodBookedGRATDates(allGRAT);
      const allCasinoOwners = response.data.allSpecialClientIds.filter((id) => id.clientType === 'casinoowner');
      setPeriodBookedCasinoOwnerDates(allCasinoOwners);
      setPeriodDays(response.data.days);
    } catch (error) {
      console.log('Error', error);
    }
  };

  return (
    <BookingsBoxContext.Provider value={{
      periodUsers,
      setPeriodUsers,
      periodBookedDates,
      setPeriodBookedDates,
      periodBookedSpecialClientDates,
      setPeriodBookedSpecialClientDates,
      periodVIPClients,
      setPeriodVIPClients,
      periodBookedHotelDates,
      setPeriodBookedHotelDates,
      periodBookedCasinoDates,
      setPeriodBookedCasinoDates,
      periodBookedEventsDates,
      setPeriodBookedEventsDates,
      periodBookedGRATDates,
      setPeriodBookedGRATDates,
      periodBookedCasinoOwnerDates,
      setPeriodBookedCasinoOwnerDates,
      periodDays,
      setPeriodDays,
      getPeriodDays,
    }}>
      {children}
    </BookingsBoxContext.Provider>
  );

}

// Custom hook to use the BookingsBoxContext
const useBookingsBoxContext = () => {
  const context = useContext(BookingsBoxContext);
  if (!context) throw new Error('Issue with provider');
  return context;
};

export { BookingsBoxProvider, useBookingsBoxContext };
